function Add({ a, b }) {
  return (
    <div>
      <h2>Add</h2>
      <p>
        {a} + {b} = {a + b}
      </p>
    </div>
  );
}
export default Add;
